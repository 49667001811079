// extracted by mini-css-extract-plugin
export var author = "diaryTemplate-module--author--5e592";
export var date = "diaryTemplate-module--date--b1fa1";
export var decrypted = "diaryTemplate-module--decrypted--aa044";
export var encrypted = "diaryTemplate-module--encrypted--e9d41";
export var flexBreak = "diaryTemplate-module--flex-break--7682f";
export var link = "diaryTemplate-module--link--bf8bf";
export var linkNext = "diaryTemplate-module--link-next--e49ce";
export var linkPrevious = "diaryTemplate-module--link-previous--9790f";
export var links = "diaryTemplate-module--links--f8c3a";
export var location = "diaryTemplate-module--location--f1042";
export var meta = "diaryTemplate-module--meta--0a835";
export var page = "diaryTemplate-module--page--51c99";
export var pagenav = "diaryTemplate-module--pagenav--30688";
export var showAll = "diaryTemplate-module--showAll--6d6e7";
export var title = "diaryTemplate-module--title--4725c";
export var toolbar = "diaryTemplate-module--toolbar--d2533";
export var url = "diaryTemplate-module--url--e0703";
export var wrapper = "diaryTemplate-module--wrapper--c5d7e";