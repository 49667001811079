import React from 'react';
import * as styles from './index.module.scss';
import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import DiaryTemplate from '../../templates/diaryTemplate';
import { Logo } from '../../components/Logo';
import moment from 'moment';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

const cx = classNames.bind(styles);

const Flag = ({ className, nation }) => {
  const colors = nation.colors?.split(/\s*:\s*/g).map(x => x.trim());

  return (
    <div className={cx('flagContainer', className)}>
      <div className={cx('flag')}>
        {colors && (
          <div className={cx('colors')}>
            {colors.map((color, i) => (
              <span style={{ backgroundColor: color }} key={i} />
            ))}
          </div>
        )}
        <Logo />
      </div>
    </div>
  );
};

const Nation = ({ nation }) => {
  let status;
  let consideration = false;
  let donor = false;

  if (nation.accessionDate?.trim()) {
    status = `Acceeded to Wisdom and Love\n${moment(
      nation.accessionDate?.trim()
    ).format('ddd Do MMMM YYYY')}`;
  } else if (nation.referendumDate?.trim()) {
    consideration = true;
    status = `Referendum for accession to Wisdom and Love\n${moment(
      nation.referendumDate?.trim()
    ).format('ddd Do MMMM YYYY')}`;
  } else if (nation.invitedDate?.trim()) {
    consideration = true;
    status = `Invited to Wisdom and Love\n${moment(
      nation.invitedDate?.trim()
    ).format('ddd Do MMMM YYYY')}`;
  } else if (nation.donorDate?.trim()) {
    donor = true;
    status = `Call to Donate to Wisdom and Love\n${moment(
      nation.donorDate?.trim()
    ).format('ddd Do MMMM YYYY')}`;
  } else {
    return null;
  }

  return (
    <li className={cx('nation', { consideration, donor })}>
      <a href={`/nations/${nation.isoCode.toLowerCase()}/`}>
        <Flag nation={nation} className={cx('nationFlag')} />
        <div className={cx('nationMeta')}>
          <span className={cx('nationName')}>{nation.name}</span>
          <span className={cx('nationStatus')}>{status}</span>
        </div>
      </a>
    </li>
  );
};

export default function Nations({ data: { markdownRemark, nations } }) {
  nations = nations.nodes.slice();

  nations.sort((a, b) => {
    if (
      (a.accessionDate?.trim() || '9999') > (b.accessionDate?.trim() || '9999')
    ) {
      return 1;
    }

    if (
      (a.accessionDate?.trim() || '9999') < (b.accessionDate?.trim() || '9999')
    ) {
      return -1;
    }

    if (
      (a.referendumDate?.trim() || '9999') >
      (b.referendumDate?.trim() || '9999')
    ) {
      return 1;
    }

    if (
      (a.referendumDate?.trim() || '9999') <
      (b.referendumDate?.trim() || '9999')
    ) {
      return -1;
    }

    if ((a.donorDate?.trim() || '9999') > (b.donorDate?.trim() || '9999')) {
      return 1;
    }

    if ((a.donorDate?.trim() || '9999') < (b.donorDate?.trim() || '9999')) {
      return -1;
    }

    if ((a.invitedDate?.trim() || '9999') > (b.invitedDate?.trim() || '9999')) {
      return 1;
    }

    if ((a.invitedDate?.trim() || '9999') < (b.invitedDate?.trim() || '9999')) {
      return -1;
    }

    return 0;
  });

  const mapData = nations.map(nation => {
    return { country: nation.isoCode.toLowerCase(), value: 1 };
  });

  const mapTooltip = ({ countryCode }) => {
    const nation = nations.find(
      ({ isoCode }) => isoCode.toLowerCase() === countryCode.toLowerCase()
    );

    if (!nation) {
      return null;
    }

    return nation.name;
  };

  const mapStyle = ({ countryCode }) => {
    const nation = nations.find(
      ({ isoCode }) => isoCode.toLowerCase() === countryCode.toLowerCase()
    );

    if (!nation) {
      return {
        fill: 'white',
        stroke: 'black',
        strokeWidth: '1px',
      };
    }

    if (nation.accessionDate?.trim()) {
      return {
        fill: '#9246da',
        cursor: 'pointer',
      };
    } else if (nation.referendumDate?.trim()) {
      return {
        fill: 'goldenrod',
        cursor: 'pointer',
      };
    } else if (nation.invitedDate?.trim()) {
      return {
        fill: 'gold',
        cursor: 'pointer',
      };
    }
  };

  return (
    <>
      <DiaryTemplate
        data={{
          markdownRemark,
        }}
        includeMeta={true}
        includeBacklinks={false}
        pageBreakBefore={true}
      />
      <div className={cx('map')}>
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{ scale: 120 }}
        >
          <Geographies geography="/world-map.json">
            {({ geographies }) =>
              geographies.map(geo => {
                const nation = nations.find(
                  ({ isoA3Code }) =>
                    isoA3Code.toLowerCase() === geo?.id?.toLowerCase()
                );

                if (
                  !nation ||
                  (!nation.donorDate?.trim() &&
                    !nation.invitedDate?.trim() &&
                    !nation.referendumDate?.trim() &&
                    !nation.accessionDate?.trim())
                ) {
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#ffffff"
                      stroke="#dddddd"
                    />
                  );
                }

                if (nation.accessionDate?.trim()) {
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#9246da"
                    />
                  );
                } else if (nation.referendumDate?.trim()) {
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="goldenrod"
                    />
                  );
                } else if (nation.invitedDate?.trim()) {
                  return (
                    <Geography key={geo.rsmKey} geography={geo} fill="gold" />
                  );
                } else if (nation.donorDate?.trim()) {
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#3c3b6e"
                    />
                  );
                }
              })
            }
          </Geographies>
        </ComposableMap>
      </div>
      <ul className={cx('nations')}>
        {nations.map(nation => (
          <Nation key={nation.isoCode} nation={nation} />
        ))}
      </ul>
    </>
  );
}

export const pageQuery = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/nations/" } }) {
      html
      frontmatter {
        dateString: date
        date(formatString: "ddd Do MMMM YYYY H:mm:ss a z")
        title
        author
        location
        showTitle
        url
        categories
        twitter
        facebook
        className
        showLink
        showMeta
        showRead
      }
      fields {
        encrypted
        slug
      }
      hero {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 1.34
            transformOptions: { fit: COVER, cropFocus: ATTENTION }
          )
        }
      }
    }
    nations: allGoogleSpreadsheetNations {
      nodes {
        gdp
        gdpPerCapita
        isoCode
        isoA3Code

        name
        population
        colors
        donorDate
        invitedDate
        referendumDate
        accessionDate
      }
    }
  }
`;
